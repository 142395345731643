<template>
	<section>
		<el-page-header @back="$router.go(-1)" content="訂單詳情" />
		<br>
		<div style="width:100%; max-width:1000px;">
			<el-form label-width="100px" label-position="left">
				<h2 class="theme_text">訂單資訊</h2>
				<el-divider />
				<el-form-item label="訂單編號">
					{{_order.id}}
				</el-form-item>
				<el-form-item label="商品">
					<table>
						<tr v-for="(item, i) in _order.list" :key="i">
							<td>
								<el-link type="primary">
									<router-link :to="`/product/${item.productId}`">{{item.name}}</router-link>
								</el-link>
							</td>
							<td>
								<template v-if="item.color || item.size">
									{{item.color}}
									<template v-if="item.color && item.size">、</template>
									{{item.size}}
								</template>
							</td>
							<td>
								<small>（&times;{{item.amount}}）</small>
							</td>
							<td>
								<small>{{item.price | currencyFilter}}</small>
							</td>
						</tr>
						<tr>
							<td></td>
							<td></td>
							<td><b class="theme_text" style="font-size:1.5em;">總金額</b></td>
							<td><b class="theme_text" style="font-size:1.5em;">{{_order.totalPrice | currencyFilter}}</b></td>
						</tr>
					</table>
				</el-form-item>
				<h2 class="theme_text">訂購人資訊</h2>
				<el-divider />
				<el-form-item label="姓名">
					{{_order.orderer && _order.orderer.name}}
				</el-form-item>
				<el-form-item label="手機號碼">
					{{_order.orderer && _order.orderer.cellphone}}
				</el-form-item>
				<el-form-item label="電子信箱">
					{{_order.orderer && _order.orderer.email}}
				</el-form-item>
				<h2 class="theme_text">收件人資訊</h2>
				<el-divider />
				<el-form-item label="姓名">
					{{_order.recipient && _order.recipient.name}}
				</el-form-item>
				<el-form-item label="手機號碼">
					{{_order.recipient && _order.recipient.cellphone}}
				</el-form-item>
				<el-form-item label="電子信箱">
					{{_order.recipient && _order.recipient.email}}
				</el-form-item>
				<el-form-item label="地址">
					{{_order.recipient && _order.recipient.zipcode}}
					{{_order.recipient && _order.recipient.city}}
					{{_order.recipient && _order.recipient.district}}
					{{_order.recipient && _order.recipient.address}}
				</el-form-item>
				<h2 class="theme_text">付款方式</h2>
				<el-divider />
				<b>{{_order.payment}}</b>
			</el-form>
			<br>
			<br>
			<br>
			<template v-if="_order.status && _order.status.code == 1">
				<div class="row" align-x="center" gutter="50">
					<popconfirm title="請填寫原因" @confirm="handleReject()" @cancel="handleClosepopconfirm()" width="500">
						<el-form ref="rejectForm" :model="$data">
							<el-form-item prop="rejectMessage" :rules="{required:true,message:'請填寫原因'}">
								<el-input v-model="rejectMessage" type="textarea" :autosize="{minRows:20}" />
							</el-form-item>
						</el-form>
						<el-button slot="reference" type="danger">拒絕訂單</el-button>
					</popconfirm>
					<popconfirm @confirm="handleConfirm()">
						<h3 style="margin-top:0;">
							<p><i class="el-icon-info" style="color:red;" />{{"\u3000"}}請確認：</p>
							<ol>
								<li>已收到款項？</li>
								<li>已經出貨？</li>
							</ol>
						</h3>
						<el-button slot="reference" type="primary">接單並出貨</el-button>
					</popconfirm>
				</div>
			</template>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Popconfirm: () => import("@/components/popconfirm"),
	},
	props: ["id"],
	data() {
		return {
			rejectMessage: ""
		}
	},
	computed: {
		...mapState("order", ["_order"])
	},
	methods: {
		...mapActions("order", [
			"_getOrder",
			"_rejectOrder",
			"_confirmOrder",
		]),
		handleReject() {
			this.$refs["rejectForm"].validate(async valid => {
				if (valid) {
					this.$message.success(await this._rejectOrder({
						id: this.id,
						message: this.rejectMessage
					}));
					this.$router.go(-1);
				}
			})
		},
		handleClosepopconfirm() {
			this.rejectMessage = "";
			this.$refs["rejectForm"].resetFields();
		},
		async handleConfirm() {
			this.$message.success(await this._confirmOrder(this.id));
			this.$router.go(-1);
		},
	},
	created() {
		this._getOrder(this.id);
	}
}
</script>

<style lang="scss" scoped>
.el-page-header {
	color: $theme;
}
table {
	line-height: 1.5em;
	background: #fff;
}
tr:nth-child(even) {
	background: $theme-background;
}
th,
td {
	padding: 0.625em 1em;
}
.el-form-item {
	margin: 0 !important;
}
.el-divider {
	height: 2px;
	background: $theme-light;
	margin-top: 0;
}
</style>